import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';

import { LoadAggregatedDashboard } from './state/aggregated-dashboard.actions';

export const aggregatedDashboardResolver: ResolveFn<boolean> = () => {
  return inject(Store)
    .dispatch(new LoadAggregatedDashboard())
    .pipe(
      map(() => true),
      catchError(() => of(false)),
    );
};

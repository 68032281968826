import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { select } from '@ngxs/store';
import { NgScrollbar } from 'ngx-scrollbar';

import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { ResizeDirective } from '@clover/core/directives/resize.directive';
import type { LoadingStatus } from '@clover/core/helpers/loading';
import { RouterSelectors } from '@clover/custom-router-state-serializer';

import { AggregatedDashboardSelectors } from './aggregated-dashboard/state/aggregated-dashboard.selectors';
import { CompanySpecificDashboardSelectors } from './company-specific-dashboard/state/company-specific-dashboard.selectors';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DASHBOARD_BASE_URL } from './routes';
import { PageWithSubnavComponent } from '../../stories/layout/page-with-subnav/page-with-subnav.component';
import { LoaderComponent } from '../../stories/misc/loader/loader.component';

@Component({
  selector: 'cc-dashboard-page',
  imports: [
    PageWithSubnavComponent,
    RouterOutlet,
    DashboardHeaderComponent,
    NgScrollbar,
    LoaderComponent,
    AutoAnimateDirective,
    CdkScrollable,
    ResizeDirective,
  ],
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPageComponent {
  private readonly params = select(RouterSelectors.params);
  readonly companyId = computed(() => {
    const companyId = this.params()['companyId'];
    return companyId ? Number(companyId) : undefined;
  });

  private readonly aggregatedDashboardLoadingStatus = select(AggregatedDashboardSelectors.loadingStatus);
  private readonly companySpecificDashboardLoadingStatus = select(CompanySpecificDashboardSelectors.loadingStatus);

  private readonly router = inject(Router);

  protected readonly loadingStatus = computed<LoadingStatus>(() => {
    const isAggregatedDashboard = !this.companyId();

    if (isAggregatedDashboard) return this.aggregatedDashboardLoadingStatus();
    return this.companySpecificDashboardLoadingStatus();
  });

  handleCompanyChange(companyId: number | undefined) {
    if (companyId === this.companyId()) return;

    if (!companyId) {
      this.router.navigate([DASHBOARD_BASE_URL, 'all']);
      return;
    }

    this.router.navigate([DASHBOARD_BASE_URL, companyId]);
  }
}

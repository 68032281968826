import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';
import { MarkdownModule } from 'ngx-markdown';

import { NoopComponent } from '@clover/core/components/noop/noop.component';
import { PermissionsGuard } from '@clover/core/guards/permissions.guard';
import { WikiState } from '@clover/network-v2/company/company-wiki/state/wiki.state';

import { aggregatedDashboardResolver } from './aggregated-dashboard/aggregated-dashboard.resolver';
import { AggregatedDashboardState } from './aggregated-dashboard/state/aggregated-dashboard.state';
import { companySpecificDashboardResolver } from './company-specific-dashboard/company-specific-dashboard.resolver';
import { CompanySpecificDashboardState } from './company-specific-dashboard/state/company-specific-dashboard.state';
import { DashboardPageComponent } from './dashboard-page.component';
import { relevantDashboardRedirectorGuard } from './relevant-dashboard-redirector.guard';

export const DASHBOARD_BASE_URL = 'dashboard';

export const dashboardPageRoutes: Routes = [
  {
    path: '',
    component: DashboardPageComponent,
    data: { permissions: ['Dashboard_NavMenuViewV2'] },
    canActivate: [PermissionsGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',

        // TODO (Oleksandr D.): rewrite this to RedirectFunction when Angular is updated to v18+
        // @see https://angular.dev/api/router/RedirectFunction
        component: NoopComponent,
        canActivate: [relevantDashboardRedirectorGuard],
      },
      {
        path: 'all',
        providers: [provideStates([AggregatedDashboardState])],
        loadComponent: () =>
          import('./aggregated-dashboard/aggregated-dashboard.component').then(
            ({ AggregatedDashboardComponent }) => AggregatedDashboardComponent,
          ),
        resolve: {
          _: aggregatedDashboardResolver,
        },
      },
      {
        path: ':companyId',
        providers: [
          provideStates([CompanySpecificDashboardState, WikiState]),
          importProvidersFrom(MarkdownModule.forRoot()),
        ],
        loadComponent: () =>
          import('./company-specific-dashboard/company-specific-dashboard.component').then(
            ({ CompanySpecificDashboardComponent }) => CompanySpecificDashboardComponent,
          ),
        resolve: {
          _: companySpecificDashboardResolver,
        },
      },
    ],
  },
];

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';

import { CompanyLicenseTier } from '@clover/core/models/company';
import { UserService } from '@clover/core/services/user.service';
import { AccountsService } from '@clover/network-v2/network/accounts/state/accounts.service';

import { DASHBOARD_BASE_URL } from './routes';

export const relevantDashboardRedirectorGuard: CanActivateFn = () => {
  const router = inject(Router);
  const userService = inject(UserService);

  const companyLicenseTier = userService.userCompany?.licenseTier;

  return inject(AccountsService)
    .getAccounts('', { limit: 1 }, { includeOnlyConnected: true })
    .pipe(
      map(({ data: connections, total: connectionsCount }) => {
        if (connectionsCount === 1 && companyLicenseTier === CompanyLicenseTier.Individual) {
          const [onlyCompany] = connections;
          return router.createUrlTree(['/', DASHBOARD_BASE_URL, onlyCompany.id]);
        }

        return router.createUrlTree(['/', DASHBOARD_BASE_URL, 'all']);
      }),
    );
};

import { ChangeDetectionStrategy, Component, computed, inject, model } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { interval, map, startWith } from 'rxjs';

import { UserService } from '@clover/core/services/user.service';
import { NETWORK_BASE_URL } from '@clover/network-v2/routes';
import { TooltipAlignment, TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { CompanyLogoComponent } from '../../../stories/misc/company-logo/company-logo.component';
import { UserAvatarComponent } from '../../../stories/misc/user-avatar/user-logo.component';
import { CompanyPickerComponent } from '../../../stories/overlays/company-picker/company-picker.component';

type PartOfDay = 'morning' | 'afternoon' | 'evening' | 'night';

@Component({
  selector: 'cc-dashboard-header',
  imports: [CompanyPickerComponent, UserAvatarComponent, CompanyLogoComponent, RouterLink, TooltipDirective],
  templateUrl: './dashboard-header.component.html',
  styleUrl: './dashboard-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardHeaderComponent {
  readonly companyId = model<number | undefined>(undefined);

  private readonly currentDate = toSignal(
    interval(60 * 1000).pipe(
      startWith(new Date()),
      map(() => new Date()),
    ),
  );

  protected readonly userProfile = inject(UserService).userProfile$;
  protected readonly userCompany = inject(UserService).company$;

  protected readonly partOfDay = computed<PartOfDay>(() => {
    const hours = this.currentDate().getHours();

    if (hours >= 6 && hours < 12) return 'morning';
    if (hours >= 12 && hours < 18) return 'afternoon';
    if (hours >= 18 && hours < 21) return 'evening';

    return 'night';
  });

  protected readonly NETWORK_BASE_URL = NETWORK_BASE_URL;
  protected readonly TooltipAlignment = TooltipAlignment;
  protected readonly TooltipPosition = TooltipPosition;
}

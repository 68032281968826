<cc-page-with-subnav id="dashboard-v2">
  <ng-scrollbar
    class="dashboard-page-scrollbar"
    cdkScrollable
  >
    <div
      class="dashboard-page"
      [ccAutoAnimate]
      [style.--header-height.px]="resize.size()?.height"
    >
      <cc-dashboard-header
        ccResize
        #resize="ccResize"
        class="dashboard-page__header"
        [companyId]="companyId()"
        (companyIdChange)="handleCompanyChange($event)"
      />

      @if (loadingStatus() === 'loaded') {
        <div class="dashboard-page__content">
          <router-outlet />
        </div>
      } @else if (loadingStatus() === 'loading') {
        <div class="dashboard-page__loader">
          <cc-loader />
        </div>
      } @else {
        <div class="dashboard-page__loader">
          <cc-loader [animated]="false" />
        </div>
      }
    </div>
  </ng-scrollbar>
</cc-page-with-subnav>

<header class="dashboard-header">
  <div class="dashboard-header__welcome">
    <cc-user-avatar
      [name]="[userProfile().firstName, userProfile().lastName]"
      [src]="userProfile().logoUrl"
      [size]="56"
    />

    <div class="dashboard-header__welcome-details">
      <h2 class="dashboard-header__welcome-details-title">
        @switch (partOfDay()) {
          @case ('morning') {
            Good morning, {{ userProfile().firstName }}!
          }
          @case ('afternoon') {
            Good afternoon, {{ userProfile().firstName }}!
          }
          @case ('evening') {
            Good evening, {{ userProfile().firstName }}!
          }
          @case ('night') {
            Good night, {{ userProfile().firstName }}!
          }
        }
      </h2>

      @if (userCompany(); as company) {
        <a
          class="dashboard-header__welcome-details-subtitle"
          [routerLink]="['/', NETWORK_BASE_URL, 'company', company.id]"
          ccTooltip="View your company profile"
          [tooltipAlignment]="TooltipAlignment.Start"
          [tooltipPosition]="TooltipPosition.Bottom"
        >
          <cc-company-logo
            [name]="company.name"
            [src]="company.logoUrl"
            [size]="24"
            borderRadius="6px"
          />

          {{ company.name }}
        </a>
      }
    </div>
  </div>

  <div class="dashboard-header__actions">
    <div
      class="company-selector"
      data-company-dashboard-tour-anchor="connection"
      data-aggregated-dashboard-tour-anchor="all-connections"
    >
      <p class="company-selector__title">Viewing connection:</p>

      <cc-company-picker
        class="company-selector__picker"
        [(selectedCompanyId)]="companyId"
        undefinedOptionLabel="All connections"
      />
    </div>
  </div>
</header>

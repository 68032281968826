import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';

import { DASHBOARD_BASE_URL } from '../routes';
import { LoadCompanySpecificDashboard } from './state/company-specific-dashboard.actions';

export const companySpecificDashboardResolver: ResolveFn<boolean> = (route) => {
  const companyId = Number(route.params['companyId'] as string);

  if (!companyId || isNaN(companyId)) {
    inject(Router).navigate(['/', DASHBOARD_BASE_URL]);
    return of(false);
  }

  return inject(Store)
    .dispatch(new LoadCompanySpecificDashboard(companyId))
    .pipe(
      map(() => true),
      catchError(() => of(false)),
    );
};
